import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import AvailableCategory from './AvailableCategory';
import BasicInfo from './BasicInfo';
import ImageContainer from './ImageContainer';

export default function PcSetModalIndex(props: { id: string; close: () => void; }) {
    const categoryList = ['CPU - 中央處理器', 'Motherboard - 底板', 'DESKTOP RAM -桌上電腦記憶體', 'Display Card - 顯示卡', '3.5" Hard Disk - 內置硬盤', 'External Hard Disk - 外置式硬盤',
        'SSD - 固態硬盤', 'Case - 機箱', 'POWER SUPPLY - 火牛', 'Heatsink - 散熱器', 'WATER COOLING - 水冷', 'Mini PC - 小型電腦', 'NOTEBOOK RAM -手提電腦記憶體'];
    const [update, setUpdate] = useState(false);
    const [available, setAvailable] = useState(false);
    const [category, setCategory] = useState("");
    const [chineseName, setChineseName] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [additionPrice, setAdditionPrice] = useState(0);
    const [allComponentList, setAllComponentList] = useState<{ id: string; category: string; brand: string; name: string; price: number; inStock: boolean; }[]>([]);
    const [selectedComponent, selectComponent] = useState<{ id: string; category: string; brand: string; name: string; price: number; inStock: boolean; }[]>([]);
    const [used, setUsed] = useState<{ id: string; path: string; width: number; height: number; }[]>([]);
    const [unUsed, setUnUsed] = useState<{ id: string; path: string; width: number; height: number; }[]>([]);
    const [deleted, setDeleted] = useState<{ id: string; path: string; width: number; height: number; }[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const minimumPrice = useMemo(() => {
        const categoryList = ['CPU - 中央處理器', 'Motherboard - 底板', 'DESKTOP RAM -桌上電腦記憶體', 'Display Card - 顯示卡', '3.5" Hard Disk - 內置硬盤', 'External Hard Disk - 外置式硬盤',
            'SSD - 固態硬盤', 'Case - 機箱', 'POWER SUPPLY - 火牛', 'Heatsink - 散熱器', 'WATER COOLING - 水冷', 'Mini PC - 小型電腦', 'NOTEBOOK RAM -手提電腦記憶體'];
        let min = additionPrice;
        for (const category of categoryList) {
            if (selectedComponent.filter((c) => c.category === category).length > 0) {
                const item = selectedComponent.filter((c) => c.category === category).sort((a, b) => a.price - b.price)[0];
                min += item.price;
            };
        };
        return min;
    }, [additionPrice, selectedComponent]);
    const componentList = useMemo(() => { return allComponentList }, [allComponentList]);
    useEffect(() => {
        if (props.id === "new") {
            ; (async () => {
                const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set/initial`);
                const json = await result.json();
                setAllComponentList(json.allProduct.map((component: any) => {
                    return {
                        id: component.id, category: component.category, brand: component.brand, name: component.name, price: component.price,
                        inStock: component.in_stock === 1 ? true : false
                    };
                }));
            })()
        } else {
            if (props.id !== 'new') {
                ; (async () => {
                    const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set/${props.id}`);
                    const json = await result.json();
                    setAvailable(json.available);
                    setAllComponentList(json.allProduct.map((component: any) => {
                        return {
                            id: component.id, category: component.category, brand: component.brand, name: component.name, price: component.price,
                            inStock: component.in_stock === 1 ? true : false
                        };
                    }));
                    selectComponent(json.setProductList.map((component: any) => {
                        return {
                            id: component.id, category: component.category, brand: component.brand, name: component.name, price: component.price,
                            inStock: component.in_stock === 1 ? true : false
                        };
                    }));
                    setUnUsed(json.imageList.filter((item: any) => item.in_use === 0));
                    setUsed(json.imageList.filter((item: any) => item.in_use === 1));
                    setAdditionPrice(json.price);
                    setChineseName(json.chineseName);
                })()
            };
        };
    }, [props.id, update]);

    async function updatePcSet() {
        const formData = new FormData();
        formData.append("id", props.id);
        formData.append("chineseName", chineseName);
        formData.append("englishName", englishName);
        formData.append("price", additionPrice + "");
        formData.append("specialPrice", minimumPrice + "");
        for (const item of selectedComponent) {
            formData.append("componentList[]", JSON.stringify(item));
        };
        for (const image of used) {
            formData.append("imageList[]", image.id);
        };
        for (const delImage of deleted) {
            formData.append("deleted[]", delImage.id);
        };
        if (files[0]) {
            for (const file of files[0]) {
                formData.append('images', file);
            };
        };
        if (props.id !== 'new') {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set`, {
                method: "PUT", body: formData
            });
            if (result.status === 200) { props.close(); };
        } else {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set`, {
                method: "POST", body: formData
            });
            if (result.status === 200) { props.close(); };
        };
    };
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: "50px",
            maxWidth: "1300px",
            width: "80vw",
            height: "90vh",
            boxShadow: 24,
            bgcolor: 'background.paper',
            overflow: 'scroll'
        }}>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", margin: 2 }}>
                    <BasicInfo id={props.id} onClose={() => props.close()} update={() => setUpdate(!update)} available={available} chineseName={chineseName} setChineseName={(name) => setChineseName(name)}
                        englishName={englishName} setEnglishName={(name) => setEnglishName(name)} additionPrice={additionPrice} setAdditionPrice={(price) => setAdditionPrice(price)} />
                    <div><TextField sx={{ margin: 1 }} label="最低價" type="number" value={minimumPrice}
                        InputProps={{ readOnly: true, startAdornment: (<InputAdornment position="start">$</InputAdornment>) }} /></div>
                    <Box sx={{ flex: 1 }}>
                        <List sx={{ overflow: 'scroll', height: "100%", '& ul': { padding: 0 }, }} subheader={<li />}>
                            {categoryList.map((category, i) => {
                                return (
                                    <li key={i}>
                                        <ul>
                                            <ListSubheader>{category}<IconButton onClick={() => setCategory(category)}>+</IconButton></ListSubheader>
                                            {selectedComponent.filter((c) => c.category === category).map((component, j) => {
                                                return (
                                                    <ListItem key={j} secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => selectComponent(selectedComponent.filter((p) => p.id !== component.id))}>
                                                            <HighlightOffIcon />
                                                        </IconButton>
                                                    }>
                                                        <div>{component.price}</div>
                                                        <ListItemText primary={component.brand + ' ' + component.name} />
                                                    </ListItem>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                        </List>
                    </Box>
                </Box>
                <Box sx={{ margin: 2 }}>
                    {category !== "" &&
                        <AvailableCategory name={category} list={componentList.filter((p) => p.category === category)}
                            onClickComponent={(id: string, category: string, brand: string, name: string, price: number, inStock: boolean) => {
                                if (!selectedComponent.some((c) => c.id === id)) { selectComponent(selectedComponent.concat({ id, category, brand, name, price, inStock })); setCategory("") }
                            }}
                            selectedComponent={selectedComponent} />}
                </Box>
            </Box>
            <div><ImageContainer left={used} setLeft={(list: { id: string; path: string; width: number; height: number; }[]) => { setUsed(list) }}
                middle={unUsed} setMiddle={(list: { id: string; path: string; width: number; height: number; }[]) => { setUnUsed(list) }}
                right={deleted} setRight={(list: { id: string; path: string; width: number; height: number; }[]) => { setDeleted(list) }} /></div>
            <div>
                <label htmlFor="image">產品圖片：</label>
                <input type="file" name="image" id="image" multiple onChange={(e) => {
                    if (e.currentTarget.files?.length) { setFiles([...files, e.target.files]) }
                }} />
            </div>
            <div>
                <Button variant="contained" onClick={async () => await updatePcSet()}>更新</Button>
            </div>
        </Box>
    )
}