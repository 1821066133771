import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import ProductModel from './productModel/Index';
import { Box, Tab, Tabs } from '@mui/material';
const columns: GridColDef[] = [
    { field: "partNumber", headerName: "Part Number", width: 200 },
    {
        field: 'brand',
        headerName: 'Brand',
        width: 130,
        editable: false,
    },
    {
        field: 'chineseName',
        headerName: '中文',
        width: 400,
        editable: false,
    },
    {
        field: 'englishName',
        headerName: '英文',
        width: 400,
        editable: false,
    },
    {
        field: "tag",
        headerName: "歸類",
        width: 100,
        editable: false,
    },
    {
        field: "price",
        headerName: "價錢",
        type: "number",
        width: 100,
        editable: false,
    },
    {
        field: "available",
        headerName: "網店顯示",
        type: "boolean",
        width: 130,
        editable: false,
    },
    {
        field: "inStock",
        headerName: "現貨",
        type: "boolean",
        width: 120,
        editable: false,
    },
    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params: GridValueGetterParams) =>
    //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
];

export default function CategoryIndex() {
    const params = useParams();
    const id = params.id;
    const [type, setType] = useState<"current" | "new" | 'all'>((id === "31" || id === "36") ? "new" : "current");
    const [rows, setRows] = useState<{
        partNumber: string; brand: string; chineseName: string; englishName: string; tag: string;
        price: number; specialPrice: number; reviewed: boolean; inStock: boolean; available: boolean; reservation: boolean;
    }[]>([]);
    const [search, setSearch] = useState("");
    const [productModel, setProductModel] = useState("");
    const [update, setUpdate] = useState(false);
    const filteredRow = useMemo(() => {
        let list = rows;
        if (search !== '') {
            list = rows.filter((item) => item.chineseName.toUpperCase().includes(search.toUpperCase()) || item.partNumber.toUpperCase().includes(search.toUpperCase()));
        };
        if (type === "current") {
            return list.filter((product) => product.available);
        };
        if (type === "new") {
            return list.filter((product) => !product.reviewed);
        };
        return list;
    }, [type, rows, search]);
    const ModelComponent = forwardRef(() => <ProductModel id={productModel} closeModal={() => { setUpdate(!update); setProductModel(""); }} />);
    useEffect(() => {
        setSearch("");
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/category/${id}`);
            const json = await result.json();
            setRows(json.result);
        })()
    }, [id]);
    useEffect(() => {
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/category/${id}`);
            const json = await result.json();
            setRows(json.result);
        })()
    }, [id, update]);
    return (
        <div>
            <div><label htmlFor="search">Search</label><input type="search" name="search" id="search" value={search} onChange={(e) => setSearch(e.currentTarget.value)} /></div>
            {(id !== "31" && id !== "36") && <Box>
                <Tabs value={type} onChange={(e, value) => setType(value)}>
                    <Tab label="現有貨品" value="current" />
                    <Tab label="新到貨品" value="new" />
                    <Tab label="全部貨品" value="all" />
                </Tabs>
            </Box>}
            <DataGrid rows={filteredRow} columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick
                onCellClick={(e) => setProductModel(e.id + "")}
            />
            <Modal open={productModel !== ""} onClose={() => setProductModel("")}>
                <ModelComponent />
            </Modal>
        </div>
    )
}