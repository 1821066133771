import { useEffect, useState, forwardRef } from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import ModalIndex from "./Modal/Index";

export default function BillIndex() {
    const [filter, setFilter] = useState("待確認");
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [totalBills, setTotalBills] = useState(100);
    const [id, setID] = useState("");
    const [list, setList] = useState<{ id: string; customer: string; tel: string; price: number; status: string }[]>([]);
    const ModalComponent = forwardRef(() => <ModalIndex id={id} onClose={() => { setID(""); setUpdate(!update) }} />);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 110 },
        {
            field: 'customer',
            headerName: '顧客',
            width: 150,
            editable: false,
        },
        {
            field: 'tel',
            headerName: '電話',
            // type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: "price",
            headerName: "價錢",
            type: "number",
            width: 100,
            editable: false,
        },
        {
            field: "status",
            headerName: "狀態",
            type: "string",
            width: 100,
            editable: false,
            sortable: false
        },
        {
            field: "date",
            headerName: "時間",
            type: "dateTime",
            width: 200,
            editable: false,
        }
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        //     valueGetter: (params: GridValueGetterParams) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];
    useEffect(() => {
        ; (async () => {
            const status = filter === "已取消" ? "cancel" : filter === "未付款" ? "notPay" : filter === "待確認" ? "toConfirm" : filter === "已確認" ? "confirmed" : filter === "已完成" ? "finished" : "all";
            setLoading(true);
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/bill/type/${status}/${paginationModel.page}/${paginationModel.pageSize}`);
            const json = await result.json();
            setList(json.result.map((bill: any) => { return { ...bill, date: new Date(bill.created_at) } }));
            setTotalBills(json.totalCount);
            setLoading(false);
        })()
    }, [update, filter,paginationModel]);
    return (
        <div>
            {/* {loading ? <>
                <Stack>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rounded" width={210} height={60} />
                </Stack>
            </> : <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={filter} onChange={(e, value) => setFilter(value)} aria-label="basic tabs example">
                        <Tab label="全部" value="全部" />
                        <Tab label="已取消" value="已取消" />
                        <Tab label="未付款" value="未付款" />
                        <Tab label="待確認" value="待確認" />
                        <Tab label="已確認" value="已確認" />
                        <Tab label="已完成" value="已完成" />
                    </Tabs>
                    <BillTable filter={filter} data={list} selectBill={(id) => setID(id)} totalBills={totalBills}/>
                </Box>
            </>} */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={filter} onChange={(e, value) => setFilter(value)} aria-label="basic tabs example">
                    <Tab label="全部" value="全部" />
                    <Tab label="已取消" value="已取消" />
                    <Tab label="未付款" value="未付款" />
                    <Tab label="待確認" value="待確認" />
                    <Tab label="已確認" value="已確認" />
                    <Tab label="已完成" value="已完成" />
                </Tabs>
                {/* <BillTable filter={filter} data={list} selectBill={(id) => setID(id)} totalBills={totalBills} /> */}
                <DataGrid rows={list}
                    columns={columns} rowCount={totalBills}
                    loading={loading}
                    pageSizeOptions={[20]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    onCellClick={(e) => setID(e.id + "")}
                />
            </Box>
            <Modal open={id !== ""} onClose={() => setID("")}><ModalComponent /></Modal>
        </div>
    )
}