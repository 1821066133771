import { Fragment, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from "@mui/material/MenuItem";

function not(a: readonly { id: string; path: string; width: number; height: number; }[],
    b: readonly { id: string; path: string; width: number; height: number; }[]) {
    return a.filter((value) => b.indexOf(value) === -1);
};

function intersection(a: readonly { id: string; path: string; width: number; height: number; }[],
    b: readonly { id: string; path: string; width: number; height: number; }[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
};

export default function ProductModel(props: {
    id: string;
    closeModal: () => void;
}) {
    const [available, setAvailable] = useState(true);
    const [data, setData] = useState({
        id: "ID", partNumber: "PART NUMBER", chineseName: "電腦零件", englishName: "", category: "", tag: "", gtin: "", warrantyPeriod: "",
        hot: true, reservation: true, pcSetCharge: true, price: 0, specialPrice: 0, link: "", googleDescription: "",
        //CPU
        core: 0, thread: 0, cooling: true, internalDisplay: true, generation: 0, baseClock: 0, boostClock: 0, tdp: 0,
        //Motherboard
        chipSet: "", motherboardSize: 0, ddr: 0, wifi: true,
        //Desktop RAM
        capacity: 0, speed: 0, cl: 0, color: "", walt: 0,
        //Display Card
        memory: "", length: 0, displayCardSize: "", clockSpeed: "", ram: 0, oc: true, limitedEdition: "",
        //SSD
        interface: "", loadSpeed: 0, writeSpeed: 0,
        //PC Case
        size: "", sideWindow: "", rgb: true, pcCaseMotherboardSize: 0, pcCaseDisplayCardLength: 0,
        pcCaseHeatSinkLength: 0, atxPSUCompatible: true, sfxPSUCompatible: true, sfxlPSUCompatible: true,
        //Power Supply
        sizeType: "",
        //Monitor
        resolution: "", inch: 0, refreshRate: 0, panel: 0, speaker: true, heightAdjustable: true, wall: 0, curved: true,
        //Keyboard
        keyboardSwitch: "",
        //Headset
        edition: "", platform: "",
        //Case Fan
        pack: 0
    });
    const [motherboardChipSetList, setMotherboardChipSetList] = useState<string[]>([]);
    const [resolutionList, setResolutionList] = useState<string[]>([]);
    const [categoryList, setCategoryList] = useState<{ name: string; pos: string; }[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [video, setVideo] = useState<{ video: string | File }>({ video: "" });

    const [checked, setChecked] = useState<readonly { id: string; path: string; width: number; height: number; }[]>([]);
    const [left, setLeft] = useState<readonly { id: string; path: string; width: number; height: number; }[]>([]);
    const [middle, setMiddle] = useState<readonly { id: string; path: string; width: number; height: number; }[]>([]);
    const [right, setRight] = useState<readonly { id: string; path: string; width: number; height: number; }[]>([]);

    const leftChecked = intersection(checked, left);
    const middleChecked = intersection(checked, middle);
    const rightChecked = intersection(checked, right);
    const handleToggle = (item: { id: string; path: string; width: number; height: number; }) => () => {
        const currentIndex = checked.findIndex((image) => image.id === item.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const handleAllLeftToMiddle = () => {
        setMiddle(middle.concat(left));
        setLeft([]);
    };

    const handleCheckedLeftToMiddle = () => {
        setMiddle(middle.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedMiddleToLeft = () => {
        setLeft(left.concat(middleChecked));
        setMiddle(not(middle, middleChecked));
        setChecked(not(checked, middleChecked));
    };

    const handleAllMiddleToLeft = () => {
        setLeft(left.concat(middle));
        setMiddle([]);
    };

    const handleAllMiddleToLRight = () => {
        setRight(right.concat(middle));
        setMiddle([]);
    };

    const handleCheckedMiddleToRight = () => {
        setRight(right.concat(middleChecked));
        setMiddle(not(middle, middleChecked));
        setChecked(not(checked, middleChecked));
    };

    const handleAllRightToMiddle = () => {
        setMiddle(middle.concat(right));
        setRight([]);
    };

    const handleCheckedRightToMiddle = () => {
        setMiddle(middle.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title: string, items: readonly { id: string; path: string; width: number; height: number; }[]) => (
        <Paper sx={{ width: 200, maxHeight: "75vh", overflow: 'auto' }}>
            <ListSubheader>{title}</ListSubheader>
            <List dense component="div" role="list">
                {items.map((image, i) => {
                    // const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem
                            key={i}
                            role="listitem"
                            button
                            onClick={handleToggle(image)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.findIndex((c) => c.id === image.id) !== -1}
                                    tabIndex={-1} disableRipple
                                    inputProps={{ 'aria-labelledby': image.id, }}
                                />
                            </ListItemIcon>
                            <div style={{ width: "200px", height: "auto" }}>
                                <img src={image.path} width={image.width} height={image.height} alt="" style={{ width: "100%", height: "100%" }} />
                            </div>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
    const motherboardSizeList = ['', "ITX", "MaTX", "ATX"];
    useEffect(() => {
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/product/${props.id}`);
            const json = await result.json();
            setData(json.result); setCategoryList(json.result.categoryList);
            setAvailable(json.result.available);
            setRight(json.result.imageList.filter((i: any) => i.in_use === 0));
            setLeft(json.result.imageList.filter((i: any) => i.in_use === 1));
            if (json.result.category === "Motherboard - 底板") { setMotherboardChipSetList(json.result.motherboardChipSetList) };
            if (json.result.category === "Monitor - 顯示器") { setResolutionList(json.result.resolutionList); };
        })()
    }, [props.id]);
    async function switchStatus() {
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/product/${props.id}/status`, {
            method: "PUT"
        });
        if (result.status === 200) { setAvailable(!available); };
    };
    async function updateProduct() {
        const formData = new FormData();
        for (let [key, value] of Object.entries(data)) { formData.append(key, value + ""); };
        formData.append('inuseImageList', JSON.stringify(left));
        formData.append('deletedImageList', JSON.stringify(right));
        if (files[0]) {
            for (const file of files[0]) {
                formData.append('images', file);
            };
        };
        if (video.video) { formData.append('video', video.video); };
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/product/${props.id}`, {
            method: "POST", body: formData
        });
        const json = await result.json();
        if (json.result === "success") { props.closeModal(); } else { };
    }
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: "50px",
            maxWidth: "750px",
            width: "80vw",
            maxHeight: "90vh",
            overflowY: "scroll",
            boxShadow: 24,
            bgcolor: 'background.paper'
        }}>
            <Box sx={{ '& .MuiTextField-root': { m: 1 }, }}>
                <div>
                    <FormControlLabel control={<Switch sx={{ margin: 1 }} checked={available} onChange={async () => await switchStatus()} />} label="使用狀態" />
                    <TextField label="ID" variant="standard" InputProps={{ readOnly: true }} value={data.id} />
                    <TextField label="Part Number" variant="standard" InputProps={{ readOnly: true }} value={data.partNumber} />
                </div>
                <div>{`https://www.cap.com.hk/zh/product/${data.englishName}/${data.id}`}</div>
                <div>相</div>
                {left.map((image, i) => {
                    return (
                        <div key={i}>{'https://asset.cap.com.hk' + image.path}</div>
                    )
                })}
                <div>
                    <TextField fullWidth label="中文名稱" value={data.chineseName} onChange={(e) => setData({ ...data, chineseName: e.currentTarget.value })} />
                </div>
                <div>
                    <TextField fullWidth label="英文名稱" value={data.englishName} onChange={(e) => setData({ ...data, englishName: e.currentTarget.value })} />
                </div>
                <div>
                    <FormControl sx={{ width: 200, margin: 1 }}>
                        <InputLabel>類別</InputLabel>
                        <Select value={data.category} onChange={(e) => setData({ ...data, category: e.target.value })}>
                            {categoryList.map((category, i) => {
                                return (
                                    <MenuItem key={i} value={category.pos}>{category.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Tag" value={data.tag} onChange={(e) => setData({ ...data, tag: e.currentTarget.value })} />
                    <TextField label="GTIN" value={data.gtin} onChange={(e) => setData({ ...data, gtin: e.currentTarget.value })} />
                </div>
                <div>
                    {/* <FormControl sx={{ width: 150, margin: 1 }}>
                        <FormControlLabel control={<Checkbox checked={data.hot} onChange={() => setData({ ...data, hot: !data.hot })} />} label="熱賣商品" />
                    </FormControl> */}
                    <FormControl sx={{ width: 150, margin: 1 }}>
                        <FormControlLabel control={<Checkbox checked={data.reservation} onChange={() => setData({ ...data, reservation: !data.reservation })} />} label="可預訂" />
                    </FormControl>
                    <FormControl sx={{ width: 150, margin: 1 }}>
                        <FormControlLabel control={<Checkbox checked={data.pcSetCharge} onChange={() => setData({ ...data, pcSetCharge: !data.pcSetCharge })} />} label="電腦砌機費用" />
                    </FormControl>
                </div>
                <div>
                    <TextField label="保養" variant="standard" value={data.warrantyPeriod} onChange={(e) => setData({ ...data, warrantyPeriod: e.currentTarget.value })} />
                    <TextField label="POS價" variant="standard" helperText="改變POS中網店價錢以修改此價" value={data.price}
                        InputProps={{ readOnly: true, startAdornment: (<InputAdornment position="start">$</InputAdornment>) }}
                    />
                    <TextField label="網店價" variant="standard" helperText="" type="number" value={data.specialPrice}
                        InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>) }}
                        onChange={(e) => setData({ ...data, specialPrice: +e.currentTarget.value })} />
                </div>
                <div>
                    <TextField fullWidth label="網站" value={data.link} onChange={(e) => setData({ ...data, link: e.currentTarget.value })} />
                </div>
                <div>
                    <TextField fullWidth multiline label="Google用簡介（五十字左右）" value={data.googleDescription} onChange={(e) => setData({ ...data, googleDescription: e.currentTarget.value })} />
                </div>
                {data.category === 'CPU - 中央處理器' && <Fragment>
                    <div>
                        <FormControlLabel control={<Checkbox />} label="附散熱" checked={data.cooling} onChange={() => setData({ ...data, cooling: !data.cooling })} />
                        <FormControlLabel control={<Checkbox />} label="內置Display" checked={data.internalDisplay} onChange={() => setData({ ...data, internalDisplay: !data.internalDisplay })} />
                    </div>
                    <div>
                        <TextField label="Core" type="number" value={data.core} onChange={(e) => setData({ ...data, core: +e.currentTarget.value })} />
                        <TextField label="Thread" type="number" value={data.thread} onChange={(e) => setData({ ...data, thread: +e.currentTarget.value })} />
                        <TextField label="Generation" type="number" value={data.generation} onChange={(e) => setData({ ...data, generation: +e.currentTarget.value })} />
                        <TextField label="Base Clock" type="number" value={data.baseClock} onChange={(e) => setData({ ...data, baseClock: +e.currentTarget.value })} />
                        <TextField label="Boost Clock" type="number" value={data.boostClock} onChange={(e) => setData({ ...data, boostClock: +e.currentTarget.value })} />
                        <TextField label="TDP" type="number" value={data.tdp} onChange={(e) => setData({ ...data, tdp: +e.currentTarget.value })} />
                    </div>
                </Fragment>}
                {data.category === "Motherboard - 底板" && <Fragment>
                    <div>
                        <FormControlLabel control={<Checkbox />} label="WIFI" checked={data.wifi} onChange={() => setData({ ...data, wifi: !data.wifi })} />
                        <Autocomplete sx={{ width: 200 }} freeSolo options={motherboardChipSetList} value={data.chipSet}
                            onInputChange={(e, value) => setData({ ...data, chipSet: value === null ? '' : value })} renderInput={(params) => <TextField {...params} label="Chip_Set" />} />
                        <FormControl sx={{ width: 100, margin: 1 }}>
                            <InputLabel>{"尺寸"}</InputLabel>
                            <Select value={data.motherboardSize} onChange={(e) => setData({ ...data, motherboardSize: +e.target.value })}>
                                {motherboardSizeList.map((size, i) => {
                                    return (
                                        <MenuItem key={i} value={i}>{size}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="DDR" type="number" value={data.ddr} onChange={(e) => setData({ ...data, ddr: +e.currentTarget.value })} />
                    </div>
                </Fragment>}
                {data.category === "DESKTOP RAM -桌上電腦記憶體" && <Fragment>
                    <TextField label="容量" type="number" value={data.capacity} onChange={(e) => setData({ ...data, capacity: +e.currentTarget.value })} />
                    <TextField label="速度" type="number" value={data.speed} onChange={(e) => setData({ ...data, speed: +e.currentTarget.value })} />
                    <TextField label="CL" type="number" value={data.cl} onChange={(e) => setData({ ...data, cl: +e.currentTarget.value })} />
                    <TextField label="Walt" type="number" value={data.walt} onChange={(e) => setData({ ...data, walt: +e.currentTarget.value })} />
                    <TextField label="DDR" type="number" value={data.ddr} onChange={(e) => setData({ ...data, ddr: +e.currentTarget.value })} />
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                </Fragment>}
                {data.category === "Display Card - 顯示卡" && <Fragment>
                    <TextField label="晶片" value={data.chipSet} onChange={(e) => setData({ ...data, chipSet: e.currentTarget.value })} />
                    <TextField label="記憶體" value={data.memory} onChange={(e) => setData({ ...data, memory: e.currentTarget.value })} />
                    <TextField label="長度" type="number" value={data.length} onChange={(e) => setData({ ...data, length: + e.currentTarget.value })} />
                    <TextField label="尺寸" value={data.displayCardSize} onChange={(e) => setData({ ...data, displayCardSize: e.currentTarget.value })} />
                    <TextField label="Clock Speed" value={data.clockSpeed} onChange={(e) => setData({ ...data, clockSpeed: e.currentTarget.value })} />
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                    <TextField label="RAM" type="number" value={data.ram} onChange={(e) => setData({ ...data, ram: + e.currentTarget.value })} />
                    <TextField label="特別版本" value={data.limitedEdition} onChange={(e) => setData({ ...data, limitedEdition: e.currentTarget.value })} />
                </Fragment>}
                {data.category === "SSD - 固態硬盤" && <Fragment>
                    <TextField label="容量" type="number" value={data.capacity} onChange={(e) => setData({ ...data, capacity: +e.currentTarget.value })} />
                    <TextField label="介面" value={data.interface} onChange={(e) => setData({ ...data, interface: e.currentTarget.value })} />
                    <TextField label="讀取速度" type="number" value={data.loadSpeed} onChange={(e) => setData({ ...data, loadSpeed: +e.currentTarget.value })} />
                    <TextField label="寫入速度" type="number" value={data.writeSpeed} onChange={(e) => setData({ ...data, writeSpeed: +e.currentTarget.value })} />
                </Fragment>}
                {(data.category === '3.5" Hard Disk - 內置硬盤' || data.category === 'External Hard Disk - 外置式硬盤') && <Fragment>
                    <TextField label="容量" type="number" value={data.capacity} onChange={(e) => setData({ ...data, capacity: +e.currentTarget.value })} />
                    <TextField label="速度" type="number" value={data.speed} onChange={(e) => setData({ ...data, speed: +e.currentTarget.value })} />
                </Fragment>}
                {data.category === "Case - 機箱" && <Fragment>
                    <div>
                        <FormControl sx={{ width: 150, margin: 1 }}>
                            <FormControlLabel control={<Checkbox />} label="RGB" checked={data.rgb} onChange={() => setData({ ...data, rgb: !data.rgb })} />
                        </FormControl>
                        <FormControl sx={{ width: 150, margin: 1 }}>
                            <FormControlLabel control={<Checkbox />} label="ATX" checked={data.atxPSUCompatible} onChange={() => setData({ ...data, atxPSUCompatible: !data.atxPSUCompatible })} />
                        </FormControl>
                        <FormControl sx={{ width: 150, margin: 1 }}>
                            <FormControlLabel control={<Checkbox />} label="SFX" checked={data.sfxPSUCompatible} onChange={() => setData({ ...data, sfxPSUCompatible: !data.sfxPSUCompatible })} />
                        </FormControl>
                        <FormControl sx={{ width: 150, margin: 1 }}>
                            <FormControlLabel control={<Checkbox />} label="SFX-L" checked={data.sfxlPSUCompatible} onChange={() => setData({ ...data, sfxlPSUCompatible: !data.sfxlPSUCompatible })} />
                        </FormControl>
                    </div>
                    <div>
                        <TextField label="尺寸" value={data.size} onChange={(e) => setData({ ...data, size: e.currentTarget.value })} />
                        <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                        <TextField label="側面" value={data.sideWindow} onChange={(e) => setData({ ...data, sideWindow: e.currentTarget.value })} />
                        <FormControl sx={{ width: 150, margin: 1 }}>
                            <InputLabel>{"可容納主機板尺寸"}</InputLabel>
                            <Select value={data.pcCaseMotherboardSize} onChange={(e) => setData({ ...data, pcCaseMotherboardSize: +e.target.value })}>
                                {motherboardSizeList.map((size, i) => {
                                    return (
                                        <MenuItem key={i} value={i}>{size}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="可容納顯示卡長度" type="number" value={data.pcCaseDisplayCardLength} onChange={(e) => setData({ ...data, pcCaseDisplayCardLength: + e.currentTarget.value })} />
                        <TextField label="可容納散熱器高度" type="number" value={data.pcCaseHeatSinkLength} onChange={(e) => setData({ ...data, pcCaseHeatSinkLength: + e.currentTarget.value })} />
                    </div>
                </Fragment>}
                {data.category === "POWER SUPPLY - 火牛" && <div>
                    <TextField label="尺寸" value={data.size} onChange={(e) => setData({ ...data, size: e.currentTarget.value })} />
                    <TextField label="外形尺寸" value={data.sizeType} onChange={(e) => setData({ ...data, sizeType: e.currentTarget.value })} />
                </div>}
                {data.category === "NOTEBOOK RAM -手提電腦記憶體" && <div>
                    <TextField label="容量" type="number" value={data.capacity} onChange={(e) => setData({ ...data, capacity: +e.currentTarget.value })} />
                    <TextField label="速度" type="number" value={data.speed} onChange={(e) => setData({ ...data, speed: +e.currentTarget.value })} />
                    <TextField label="CL" type="number" value={data.cl} onChange={(e) => setData({ ...data, cl: +e.currentTarget.value })} />
                    <TextField label="Walt" type="number" value={data.walt} onChange={(e) => setData({ ...data, walt: +e.currentTarget.value })} />
                </div>}
                {data.category === "Monitor - 顯示器" && <Fragment>
                    <div>
                        <FormControl sx={{ width: 150, margin: 1 }}>
                            <FormControlLabel control={<Checkbox checked={data.heightAdjustable} onChange={() => setData({ ...data, heightAdjustable: !data.heightAdjustable })} />} label="可調較高度" />
                        </FormControl>
                        <FormControl sx={{ width: 130, margin: 1 }}>
                            <FormControlLabel control={<Checkbox checked={data.curved} onChange={() => setData({ ...data, curved: !data.curved })} />} label="曲面" />
                        </FormControl>
                        <FormControl sx={{ width: 130, margin: 1 }}>
                            <FormControlLabel control={<Checkbox checked={data.speaker} onChange={() => setData({ ...data, speaker: !data.speaker })} />} label="內置喇叭" />
                        </FormControl>
                    </div>
                    <div>
                        <Autocomplete sx={{ width: 200 }} freeSolo value={data.resolution} options={resolutionList} onInputChange={(e, value) => setData({ ...data, resolution: value })}
                            renderInput={(params) => <TextField {...params} label="解像度" />} />
                        <TextField label="尺寸" type="number" value={data.inch} onChange={(e) => setData({ ...data, inch: +e.currentTarget.value })} />
                        <TextField label="刷新率" type="number" value={data.refreshRate} onChange={(e) => setData({ ...data, refreshRate: +e.currentTarget.value })} />
                        <FormControl sx={{ width: 100, margin: 1 }}>
                            <InputLabel>{"面板"}</InputLabel>
                            <Select value={data.panel} onChange={(e) => setData({ ...data, panel: +e.target.value })}>
                                <MenuItem value={0}>{""}</MenuItem>
                                <MenuItem value={1}>{"IPS"}</MenuItem>
                                <MenuItem value={2}>{"TN"}</MenuItem>
                                <MenuItem value={3}>{"VA"}</MenuItem>
                                <MenuItem value={4}>{"OLED"}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 200, margin: 1 }}>
                            <InputLabel>{"掛牆"}</InputLabel>
                            <Select value={data.wall} onChange={(e) => setData({ ...data, wall: +e.target.value })}>
                                <MenuItem value={0}>{""}</MenuItem>
                                <MenuItem value={1}>{"冇"}</MenuItem>
                                <MenuItem value={2}>{"VESA Mount 100"}</MenuItem>
                                <MenuItem value={3}>{"VESA Mount 75"}</MenuItem>
                            </Select>
                        </FormControl>
                    </div></Fragment>}
                {data.category === "Mouse" && <Fragment><div>
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                </div></Fragment>}
                {data.category === "Keyboard" && <Fragment><div>
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                    <TextField label="軸" value={data.keyboardSwitch} onChange={(e) => setData({ ...data, keyboardSwitch: e.currentTarget.value })} />
                </div></Fragment>}
                {data.category === "Headset" && <Fragment><div>
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                    <TextField label="版本" value={data.edition} onChange={(e) => setData({ ...data, edition: e.currentTarget.value })} />
                    <TextField label="平台" value={data.platform} onChange={(e) => setData({ ...data, platform: e.currentTarget.value })} />
                </div></Fragment>}
                {data.category === "FAN - 風扇" && <Fragment><div>
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                    <TextField label="尺寸" value={data.size} onChange={(e) => setData({ ...data, size: e.currentTarget.value })} />
                    <TextField label="包裝" type="number" value={data.pack} onChange={(e) => setData({ ...data, pack: +e.currentTarget.value })} />
                </div></Fragment>}
                {data.category === "WATER COOLING - 水冷" && <Fragment><div>
                    <FormControl sx={{ width: 150, margin: 1 }}>
                        <FormControlLabel control={<Checkbox />} label="RGB" checked={data.rgb} onChange={() => setData({ ...data, rgb: !data.rgb })} />
                    </FormControl>
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                    <TextField label="尺寸" value={data.size} onChange={(e) => setData({ ...data, size: e.currentTarget.value })} />
                </div></Fragment>}
                {data.category === "Router" && <Fragment><div>
                    <TextField label="顏色" value={data.color} onChange={(e) => setData({ ...data, color: e.currentTarget.value })} />
                    <TextField label="包裝" type="number" value={data.pack} onChange={(e) => setData({ ...data, pack: +e.currentTarget.value })} />
                </div></Fragment>}
                <div>
                    <label htmlFor="image">產品圖片：</label>
                    <input type="file" name="image" id="image" multiple onChange={(e) => {
                        if (e.currentTarget.files?.length) { setFiles([...files, e.target.files]) }
                    }} />
                </div>
                <div>
                    <label htmlFor="video">{"短片："}</label>
                    <input type="file" name="video" id="video" onChange={(e) => {
                        if (e.currentTarget.files?.length) { setVideo({ video: e.currentTarget.files[0] }) }
                    }} />
                </div>
                {/* Set Order */}
                <div>
                    <Grid container spacing={2} justifyContent="center" >
                        <Grid item>{customList("正在使用", left)}</Grid>
                        <Grid item display="flex" alignItems="center">
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeftToMiddle}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeftToMiddle}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedMiddleToLeft}
                                    disabled={middleChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllMiddleToLeft}
                                    disabled={middle.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList("未使用", middle)}</Grid>
                        <Grid item display="flex" alignItems="center">
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllMiddleToLRight}
                                    disabled={middle.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedMiddleToRight}
                                    disabled={middleChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRightToMiddle}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRightToMiddle}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList("刪除", right)}</Grid>
                    </Grid>
                </div>
                <div>
                    <Button variant="contained" onClick={async () => await updateProduct()}>更新</Button>
                </div>
            </Box>
        </Box>
    )
};